@import "./components/_variables";

body {
    background-color: $orange;
    margin: 0px;
    overflow-x: hidden;

    @import "./components/landing/_landing";
    @import "./components/mailing/_mailing";
    @import "./components/footer/_footer";
    @import "./components/auth/registration/_form";
    @import "./components/mortgage/_mortgage";
    @import "./components/thanks/_thanks";
    @import "./components/charts/_charts";
    @import "./components/pipeline/_pipeline";
    @import "./components/tools/modal/_modal"; }
