.form {
    text-align: center;

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        .heading {
            color: white;
            font-size: 40px;
            margin: 40px 0px 30px;

            .logo {
                width: 200px; } }

        .form_1, .form_2 {
            .small_text {
                color: white;
                font-family: $para;
                letter-spacing: 2px;
                font-size: 12px;
                width: 300px;
                display: inline-block;
                margin-bottom: 30px;

                .underline {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    cursor: pointer;
                    color: $red; } }

            .input {
                background-color: rgba(white, 0.8);
                border: none;
                outline: none;
                padding: 15px 20px;
                font-size: 18px;
                width: calc(80% - 40px);
                border-radius: 20px;
                margin-bottom: 30px;
                font-family: $para;
                letter-spacing: 2px;

                &.phone {
                    width: calc(80% - 40px - 80px);
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    vertical-align: middle;
                    border-left: 1px solid #ddd;
                    -moz-appearance: textfield;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none; } }

                &.code {
                    -moz-appearance: textfield;
                    letter-spacing: 10px;
                    text-align: center;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none; } } }

            .country_codes {
                width: 80px;
                vertical-align: middle;
                background-color: rgba(white, 0.8);
                border: none;
                outline: none;
                padding: 15px 10px;
                font-size: 17px;
                font-family: $para;
                border-radius: 20px;
                margin-bottom: 30px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px; }

            .submit {
                background-color: $red;
                display: inline-block;
                margin-top: 20px;
                cursor: pointer;
                padding: 15px 20px;
                color: white;
                border-radius: 15px;
                font-family: $circ;
                outline: none;
                border: none;
                font-weight: 600;
                font-size: 18px; } } } }
