.landing {
    @import "./cta/_cta";
    @import "./reviews/_reviews";
    @import "./story/_story";

    .climbers {
        width: 100%;
        margin-top: -2%;
        margin-bottom: 30px; }

    .chain {
        position: absolute;
        right: -20px;
        width: 23%;
        margin-top: 60px; }

    .watch {
        width: calc(100% + 40px);
        margin-left: -15px;
        margin-top: -30px; } }
