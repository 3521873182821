.footer {
    text-align: center;
    margin-top: 50px;
    background-color: black;
    padding-bottom: 50px;
    margin-top: 60%;

    .mountain {
        margin-top: -100%;
        width: 100%;
        margin-left: -1.2%;
        margin-right: -1.2%; }

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        .logo_section {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            margin-top: 15px;

            .logo {
                color: $red;
                font-size: 40px;

                img {
                    width: calc(100% - 60px);
                    max-width: 200px; } } }

        .links {
            display: inline-block;
            vertical-align: top;
            width: calc((100% - 50%) / 2);
            margin-top: 15px;

            .links_heading {
                color: white;
                font-family: $para;
                letter-spacing: 2px;
                text-align: left;
                font-size: 16px;
                margin-bottom: 30px; }

            .link {
                padding-right: 5px;
                color: $red;
                text-align: left;
                font-size: 12px;
                margin-bottom: 25px;
                font-family: $para;
                letter-spacing: 2px;
                cursor: pointer; } } } }
