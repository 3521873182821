.reviews {
    text-align: center;

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        .heading {
            color: white;
            font-family: $para;
            letter-spacing: 2px;
            font-size: 26px;
            margin-bottom: 30px;

            .underline {
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-color: $red; } }

        .review {
            text-align: center;

            .review_contain {
                background-color: white;
                border-radius: 20px;
                margin: 5px;
                width: calc(100% - 30px);
                display: inline-block;

                .image {
                    display: inline-block;
                    vertical-align: middle;
                    width: 110px;
                    text-align: left;

                    img {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        margin: 20px; } }

                .content {
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 140px);
                    text-align: left;
                    padding: 15px 30px 15px 0px;

                    .heading {
                        color: black;
                        font-weight: 600;
                        font-family: $circ;
                        margin: 0px;
                        font-size: 18px;

                        .red {
                            color: $red; } }

                    .text {
                        font-family: $para;
                        letter-spacing: 2px;
                        font-size: 12px; } } } } } }
