.mortgage {
    text-align: center;

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        .heading {
            color: white;
            font-size: 40px;
            margin: 40px 0px -10px;

            .logo {
                width: 200px; } }

        .iframe {
            outline: none;
            border: none;
            height: auto;
            width: 100%; } } }
