@font-face {
    font-family: Circular;
    src: url("../assets/font/CircularStd-Bold.ttf"); }

// COLOURS
$orange: rgb(253, 177, 46);
$red: rgb(252, 55, 105);

// FONTS
$para: "Paralucent", sans-serif;
$circ: "Circular", sans-serif;

// DIMENSIONS
$width: 680px;
