.cta {
    text-align: center;

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        .heading {
            color: white;
            font-size: 40px;
            margin: 40px 0px 30px;

            .logo {
                width: 200px; } }

        .call {
            background-color: white;
            color: black;
            border-radius: 50px;
            padding: 15px 30px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            display: inline-block;

            .underline {
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-color: $red; } }

        .bubble {
            width: 80%; }

        .want_in {
            background-color: $red;
            display: inline-block;
            margin-top: 50px;
            padding: 15px 20px;
            cursor: pointer;
            color: white;
            border-radius: 15px;
            font-family: $circ;
            outline: none;
            border: none;
            font-weight: 600;
            font-size: 18px; }

        .small_text {
            color: white;
            font-family: $para;
            letter-spacing: 2px;
            font-size: 12px;
            margin-top: 20px;
            width: 240px;
            display: inline-block; } } }
