.mailing {
    text-align: center;
    // margin-top: 50px

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        .heading {
            color: white;
            font-size: 30px;
            font-family: $para;
            letter-spacing: 2px;
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-color: $red; }

        .small_text {
            color: white;
            font-family: $para;
            letter-spacing: 2px;
            font-size: 12px;
            margin-top: 20px;
            width: 240px;
            display: inline-block;
            margin-bottom: 30px; }

        .form {
            .input {
                background-color: rgba(white, 0.8);
                border: none;
                outline: none;
                padding: 15px 20px;
                font-size: 18px;
                width: calc(80% - 40px);
                border-radius: 20px;
                margin-bottom: 30px; }

            .submit {
                background-color: $red;
                display: inline-block;
                margin-top: 20px;
                cursor: pointer;
                padding: 15px 20px;
                color: white;
                border-radius: 15px;
                font-family: $circ;
                outline: none;
                border: none;
                font-weight: 600;
                font-size: 18px; }

            .success, .error {
                height: 28px;
                line-height: 28px;
                color: #00b100;
                font-family: $circ; }

            .error {
                color: red; } } } }
