.story {
    text-align: center;
    margin-top: 50px;

    .contain {
        width: $width;
        max-width: 100%;
        display: inline-block;

        // .bubble
        //     background-color: white
        //     color: $red
        //     display: inline-block
        //     padding: 15px
        //     font-size: 20px
        //     font-family: $sans
        //     border-radius: 25px
        //     font-weight: 600
        //     margin-bottom: 30px

        .bubble {
            width: 200px;
            margin-bottom: 15px; }

        .images {
            .image {
                display: inline-block;
                vertical-align: top;
                width: 50%;

                img {
                    width: 140px;
                    height: 140px;
                    border-radius: 50%; } }

            .caption {
                color: white;
                font-family: $circ;
                font-weight: 600;
                margin-top: 15px; } }

        .about {
            text-align: left;
            // text-align-last: center
            padding: 0px 15px;
            color: white;
            font-family: $para;
            letter-spacing: 2px;
            margin-top: 30px;
            margin-bottom: 30px; } } }
