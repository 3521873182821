.thanks {
    text-align: center;
    margin: auto;

    .contain {
        display: inline-block;

        .heading {
            color: white;
            font-size: 40px;
            margin: 40px 0px -10px;

            .logo {
                width: 200px; } }

        .content {
            color: white;
            font-family: $para;
            font-size: 20px;
            margin: 40px 0px;
            padding: 0px 30px; }

        button {
            background-color: $red;
            display: inline-block;
            margin-top: 20px;
            cursor: pointer;
            padding: 15px 20px;
            color: white;
            border-radius: 15px;
            font-family: $circ;
            outline: none;
            border: none;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 30px; } } }
