.pipeline {
    text-align: center;
    margin: auto;
    font-family: $para;

    .contain {
        display: inline-block;
        width: 880px;
        max-width: 100%;

        .heading {
            color: white;
            font-size: 40px;
            margin: 40px 0px -10px;

            .logo {
                width: 200px; } }

        .input {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            width: 100%;

            .column {
                background-color: white; }

            .column.entry {
                flex: 3;

                .top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 20px;

                    input {
                        width: 60px;
                        display: inline-block;
                        flex: 1;
                        outline: none;
                        border: none;
                        // border-bottom: 2px solid $red
                        color: $red;
                        font-size: 30px; }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0; }

                    input[type=number] {
                        -moz-appearance: textfield; /* Firefox */; }

                    div {
                        padding: 5px;
                        flex: 1;

                        img {
                            width: 100px; } } }

                .middle {
                    min-height: 324px;

                    .row {
                        display: flex;
                        flex-direction: row;
                        padding: 10px 20px;

                        .data1 {
                            text-align: left;
                            flex: 2; }

                        .data2 {
                            text-align: right;
                            flex: 1;
                            font-weight: 600;
                            color: $red; }

                        &:nth-child(2n - 1) {
                            background-color: black;

                            .data1 {
                                color: white; } } } }

                .bottom {
                    padding: 20px;

                    button {
                        background-color: $red;
                        display: inline-block;
                        padding: 15px 20px;
                        cursor: pointer;
                        color: white;
                        border-radius: 15px;
                        font-family: $circ;
                        outline: none;
                        border: none;
                        font-weight: 600;
                        font-size: 18px; } } }

            .column.map {
                border-left: 1px solid $red;
                flex: 4;

                .marker {
                    width: 20px;
                    transform: translate(-50%, -100%); } } }

        .properties {
            margin-top: 20px;

            .spinner {
                width: 20px;
                height: 20px;
                border: 3px solid black;
                border-bottom: 3px solid transparent;
                border-radius: 50%;
                display: inline-block;
                margin: 20px 0px;
                animation: spin 0.6s linear infinite; }

            .head, .property {
                display: flex;
                flex-direction: row;
                background-color: black;
                color: white;
                padding: 10px 0px;
                border-radius: 5px;

                .first {
                    flex: 4; }

                .second, .third, .fourth, .fifth {
                    flex: 1;
                    border-left: 1px solid white; } }

            .property {
                margin-top: 10px;
                background-color: white;
                color: black;
                align-items: center;

                .first {
                    text-align: left;
                    height: 50px;
                    // display: flex
                    // flex-direction: row

                    img {
                        height: 70px;
                        margin: -10px 0px;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        display: inline-block;
                        vertical-align: middle; }

                    .div {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;

                        .link {
                            font-size: 22px;
                            color: $red;
                            cursor: pointer; }

                        .title {
                            font-size: 10px; } } }

                .second, .third, .fourth, .fifth {
                    font-size: 20px;
                    color: $red;
                    font-weight: 600;

                    .small_text {
                        font-size: 14px;
                        color: black;
                        margin-top: 5px;
                        font-weight: 400; } } }

            .modal {
                .modalContent {

                    .block {
                        background-color: white;
                        margin: 10px 0px;
                        border-radius: 5px;
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        width: 100%;

                        .block_title {
                            background-color: $red;
                            color: white;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            border-top-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                            padding: 5px; }

                        .column1.flex1 {
                            flex: 1; }

                        .column1.row {
                            flex-direction: row;
                            display: flex; }

                        .column1 {
                            flex: 2;
                            border-right: 1px solid $red;

                            .bar {
                                display: flex;
                                flex-direction: column;
                                height: 100%;

                                .top {
                                    font-size: 20px;
                                    font-weight: 600; } }

                            .note {
                                text-align: left;
                                font-size: 14px;
                                padding: 10px; }

                            img {
                                border-top-left-radius: 5px;
                                width: 100%; }

                            .details {
                                display: flex;
                                flex-direction: row;
                                padding: 10px;

                                .detail.wide {
                                    flex: 2; }

                                .detail.right {
                                    text-align: right;
                                    font-weight: 600; }

                                .detail.extra {
                                    flex: 1.5; }

                                .detail {
                                    flex: 1;
                                    text-align: left; } }

                            hr {
                                margin: 5px 10px;
                                background-color: $red; }

                            .things {
                                text-align: left;
                                padding: 10px; } }

                        .column2.flex4 {
                            flex: 4; }

                        .column2 {
                            flex: 5;

                            .title_bar {
                                text-align: left;
                                margin: 0px 10px;
                                padding: 20px 0px;
                                border-bottom: 1px solid $red;
                                position: relative;

                                .title {
                                    font-size: 30px; }

                                .sub_title {
                                    margin-top: 5px; }

                                .score {
                                    background-color: $red;
                                    position: absolute;
                                    top: 0px;
                                    right: -10px;
                                    text-align: center;
                                    color: white;
                                    height: calc(100% + 1px);
                                    border-top-right-radius: 5px;
                                    padding: 0px 10px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    width: 90px;

                                    .value {
                                        font-size: 30px; } } }

                            .columns {
                                display: flex;
                                flex-direction: row;
                                padding: 10px;

                                .flex2 {
                                    flex: 2; }

                                .flex1 {
                                    flex: 1;

                                    .list {
                                        text-align: left;
                                        padding: 0px 10px;

                                        .list_title {
                                            font-weight: 600; }

                                        ul {
                                            padding-left: 15px; } }

                                    .top {
                                        text-align: left;
                                        border-bottom: 1px solid $red;
                                        padding: 10px 0px;
                                        margin: 0px 10px;
                                        font-weight: 600; }

                                    .middle {
                                        text-align: left;
                                        border-bottom: 1px solid $red;
                                        padding: 10px 0px;
                                        margin: 0px 10px;
                                        display: flex;
                                        flex-direction: row;
                                        font-size: 12px;
                                        justify-content: space-between; }

                                    .bottom {
                                        text-align: left;
                                        padding: 10px 0px;
                                        margin: 0px 10px;
                                        display: flex;
                                        flex-direction: row;
                                        font-size: 12px;
                                        justify-content: space-between; } } } } } } } } } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }
